@font-face{
  font-family: cairoR;
  src: url(./assets/fonts/cairo/Cairo-Regular.ttf);
}

.cairoR{
  font-family: cairoR;
}

@font-face{
  font-family: cairoM;
  src: url(./assets/fonts/cairo/Cairo-Medium.ttf);
}

.cairoM{
  font-family: cairoM;
}

@font-face{
  font-family: cairoS;
  src: url(./assets/fonts/cairo/Cairo-SemiBold.ttf);
}

.cairoS{
  font-family: cairoS;
}

@font-face{
  font-family: cairoB;
  src: url(./assets/fonts/cairo/Cairo-Bold.ttf);
}

.cairoB{
  font-family: cairoB;
}






@font-face{
  font-family: Glook;
  src: url(./assets/fonts/Gloock-Regular.ttf);
}

.Glook{
  font-family: Glook;
}







@font-face{
  font-family: Pop;
  src: url(./assets/fonts/poppins/Poppins-Regular.ttf);
}

.Pop{
  font-family: Pop;
}


@font-face{
  font-family: PopL;
  src: url(./assets/fonts/poppins/Poppins-Light.ttf);
}

.PopL{
  font-family: PopL;
}

@font-face{
  font-family: PopM;
  src: url(./assets/fonts/poppins/Poppins-Medium.ttf);
}

.PopM{
  font-family: PopM;
}

@font-face{
  font-family: PopT;
  src: url(./assets/fonts/poppins/Poppins-Thin.ttf);
}

.PopT{
  font-family: PopT;
}

@font-face{
  font-family: PopB;
  src: url(./assets/fonts/poppins/Poppins-Bold.ttf);
}

.PopB{
  font-family: PopB;
}

@font-face{
  font-family: PopK;
  src: url(./assets/fonts/poppins/Poppins-Black.ttf);
}

.PopK{
  font-family: PopK;
}
